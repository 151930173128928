import React from "react";
import Image from "./../components/image";
import Caption from "./../components/caption";

const Maltherapie = props => (
    <>
        <h1>Maltherapie</h1>

        <section className="columns page-section">
            <div className="column is-three-fifths">
                <h2>
                    … führt zu innerer Ordnung und befreit von belastenden
                    Emotionen
                </h2>
                <p>
                    Die alleinige Ebene der Gesprächsführung mit dem Versuch der
                    Lösung von Problemen stößt oftmals auf die Thematik, dass
                    problemreiche Situationen durch analytisches Vorgehen zwar
                    erkannt und verstanden werden, die belastenden Emotionen
                    aber nicht verschwinden. Hier zeigt die Hirnforschung, dass
                    das Gehirn in Bildern denkt und dass Änderungen auch
                    aufgrund von Bildern bewerkstelligt werden können bzw.
                    Ereignisse, die keine eigenen Bilder haben (wie z. B.
                    Gefühle) über Metaphern mit Bildern verbunden werden.
                </p>
                <p>
                    Maltherapie und Kunsttherapie versuchen über Bilder
                    unbewusste oder verdrängte Lebensinhalte sichtbar zu machen,
                    um sie dann im Gestaltungsprozess einer Lösung zuzuführen.
                </p>
            </div>
            <div className="column is-two-fifths image-column">
                <figure>
                    <Image
                        filename="wagnis-aus-liebe.jpg"
                        style={{ maxWidth: "280px", margin: "0 auto" }}
                        imageStyle={{ objectFit: "contain" }}
                        alt="Gemalter Panther mit Aufschrift: Wagnis aus Liebe"
                    />
                    <Caption style={{ maxWidth: "280px" }}>
                        Das Malen des Bildes half einen sehr angstbesetzten
                        Schritt zu wagen. Es macht das Wagnis offenbar und
                        verbindet es mit dem Grundmotiv für diesen Schritt:
                        Liebe
                    </Caption>
                </figure>
            </div>
        </section>

        <figure
            className="columns page-section"
            style={{ marginTop: "0.5rem" }}
        >
            <div
                className="column is-half image-column unfall-image"
                style={{ order: 1 }}
            >
                <Image
                    className=""
                    filename="unfall.jpg"
                    style={{ height: "330px" }}
                    imageStyle={{ objectFit: "cover" }}
                    alt="Dunkles gemaltes Bild mit weißem Strich"
                />
            </div>
            <Caption
                className="column is-half has-text-left unfall-caption"
                style={{ order: 2, textAlign: "left" }}
            >
                Bei einem Unfall zerkratzt der Radfahrer mit dem Lenker die
                Fahrertüre eines Autos. Die emotionale Belastung und Unruhe in
                den Folgetagen war sehr hoch, weil die Schadenshöhe unklar war
                und keine Haftpflicht bestand. Beim Malen der Situation, so wie
                sie der Radfahrer gesehen hatte (am Boden sitzend Spiegel und
                Wagentüre sehend), trat eine deutliche Beruhigung ein. Da eine
                gewisse Unruhe noch blieb, wurden die schwarzen Striche in der
                Autoscheibe übermalt, die dort so gar nicht vorkamen. Sofort
                verschwand das belastende Gefühl im Bauch. Mit dieser inneren
                Ruhe konnte die Schadensregelung zu einem überraschend guten,
                schnellen, entgegenkommenden Ende gebracht werden.
            </Caption>
        </figure>

        <section className="columns page-section">
            <div className="column is-three-fifths">
                <h2>
                    Störende Erinnerungsbilder werden durch störungsfreie Bilder
                    ersetzt. 
                </h2>
                <p>
                    Die lösungsorientierte Maltherapie LOM
                    <sup>&reg;</sup>, basierend auf den Untersuchungen von
                    Bettina Egger und Jörg Merz, zeigen eine weiterentwickelte
                    Art mit den Bildern des Unbewussten umzugehen.
                </p>
                <p>
                    Bilder werden so wie sie erinnert werden der Reihe nach
                    gemalt und zwar aus genau dem Blickwinkel, wie sie der/die
                    Betroffene es gesehen hat. Die Bilder werden in ihrer
                    einfachsten Form gemalt. Zustände einer Situation auf dem
                    Bild, die so nicht akzeptabel sind (z. B. fauliger Apfel),
                    werden auf dem Bild malend in Ordnung gebracht. Gefühle,
                    Emotionen oder Ereignisse, die keinen Bildern zugeordnet
                    werden können, werden mit Metaphern „aufgefangen“ und so neu
                    vom Gehirn gespeichert. Die Bilder werden langsam gemalt.
                    Das Gefühl für eine früher erlebte Situation kann sich
                    auflösen und neu – dem jetzigen Moment entsprechend –
                    empfunden werden. Trigger und Flashbacks können wirklich
                    aufgelöst werden und du landest im Jetzt, unbelastet von der
                    Vergangenheit.
                </p>
            </div>

            <div className="column is-two-fifths image-column">
                <figure>
                    <Image
                        filename="ente.jpg"
                        style={{}}
                        alt="Gemalte Ente"
                    />
                    <Caption>
                    Das Malen mit der ungeübten linken Hand ohne Pinsel auf großformatigen Papieren führt über den Tastsinn und die Maldauer zu einem intensiven Prozess, der auf Eindruck statt auf Ausdruck ausgerichtet ist. In Verbindung mit eindeutig gemalten Bildern (hier eine Metapher für ein körperliches Symptom) kommt es zu Lösungen während des Prozesses.
                    </Caption>
                </figure>
            </div>
        </section>

        <section className="columns page-section">
            <div className="column">
                <div className="card buchempfehlung">
                    <div
                        className="vcard-item vcard-item-image"
                        style={{ width: "35%", padding: "20px"}}
                    >
                        <Image
                            style={{height: "100%", maxHeight: "200px"}}
                            imageStyle={{objectFit: "contain", height: "100%"}}
                            filename="buch-loesungsorientierte-maltherapie.jpg"
                            alt="Buch-Cover: Lösungsorientierte Maltherapie. Wie Bilder Emotionen steuern"
                        />
                    </div>
                    <div
                        className="vcard-item vcard-item-content"
                        style={{ width: "65%", display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                        <small><i>Buchtipp</i></small>
                        <h4 style={{marginTop: "0.3em"}}>
                            Lösungsorientierte Maltherapie.
                            <br />
                            Wie Bilder Emotionen steuern
                        </h4>
                        <p>von Bettina Egger und Jörg Merz</p>
                        <a href="https://www.amazon.de/dp/345685272X" rel="noopener noreferrer" target="_blank">Link zum Buch auf Amazon.de</a>
                    </div>
                </div>
            </div>
        </section>
    </>
);
export default Maltherapie;
